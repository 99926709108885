


































import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import { Inject } from 'inversify-props';
import { Switch } from 'element-ui';
import CustomLoader from '@/modules/common/components/ui-kit/custom-loader.vue';
import CustomSwitch from '@/modules/common/components/ui-kit/custom-switch.vue';
import _ from 'lodash';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import CarsCompSetStore from '@/modules/cars/store/cars.comp-set.store';
import CarCompsetsService, { CarCompsetsServiceS } from '../../compsets.service';
import { CompsetsLogItemModel } from '../../models';

@Component({
    components: {
        CustomLoader,
        CustomSwitch,
        'el-switch': Switch,
    },
})
export default class CompsetsHistoryBar extends Vue {
    @Inject(CarsSharedServiceS) protected carsSharedService!: CarsSharedService;
    @Inject(CarCompsetsServiceS) protected carCompsetsService!: CarCompsetsService;

    selectedVersion: number | boolean = false;

    restoreCurrentVersion() {
        this.$emit('restoreCurrentVersion');
        this.selectedVersion = false;
    }

    async handleClick(historyId: number, item: CompsetsLogItemModel) {
        this.selectedVersion = historyId;
        this.carCompsetsService.logsChanges = item.changes;
        this.carCompsetsService.deletedItems = item.deletedItems;
        this.carCompsetsService.logVersion = item.version;
        this.carCompsetsService.showChangesOnly = false;
        await this.carCompsetsService.previewCompestSettingsLog();
    }

    async handleRestore(e: Event) {
        e.stopPropagation();
        await this.carCompsetsService.revertCompestSettingsLogs();
        this.carsCompSetStore.historyLogs.loading.reset();
        this.carsSharedService.carsFilterStoreState.filtersReady = false;
        this.carsSharedService.loadFilters();
        this.restoreCurrentVersion();
    }

    get carsCompSetStore(): CarsCompSetStore {
        return this.carCompsetsService.storeState;
    }

    formatDate(date: Date) {
        return moment(date).format('MMMM D, YYYY hh:mm A');
    }

    get items(): CompsetsLogItemModel[] {
        const { compestSettingsLogs } = this.carCompsetsService;
        if (!compestSettingsLogs) {
            return [];
        }
        return compestSettingsLogs;
    }

    get showChangesOnly() {
        return this.carCompsetsService.showChangesOnly;
    }

    set showChangesOnly(value: boolean) {
        this.carCompsetsService.showChangesOnly = value;
    }

    get isHistoryLoading(): boolean {
        return this.carsSharedService.carsCompSetStore.historyLogs.loading.isLoading();
    }

    get isDisabled() {
        return !(this.carCompsetsService.logVersion);
    }
}
