



















































import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { Col, Row, Table, TableColumn } from 'element-ui';
import _ from 'lodash';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import CarCompsetsService, { CarCompsetsServiceS } from '../../compsets.service';
import CompsetsHistoryBar from './compsets-history-bar.vue';

interface ICompsetTableData {
    country: string;
    dataSource: string;
    competitors: { name: string; value: string; }[];
}

@Component({
    components: {
        'el-table': Table,
        'el-table-column': TableColumn,
        'el-row': Row,
        'el-col': Col,
        ModalWrapper,
        CompsetsHistoryBar,
    },
})
export default class CompsetsHistoryPopup extends Vue {
    @Inject(CarCompsetsServiceS) protected carCompsetsService!: CarCompsetsService;
    @Inject(CarsFiltersServiceS) carsFiltersService!: CarsFiltersService;

    @Prop({
        required: true,
        type: Array,
    })
    public data!: [];

    public get tableData(): ICompsetTableData[] {
        const data: ICompsetTableData[] = [];
        if (this.carCompsetsService.showChangesOnly) {
            return this.loadChangesOnly;
        }
        const { documentsPreview } = this.carCompsetsService;
        if (documentsPreview && documentsPreview.data) {
            const { allowedVendorsPerCountry } = this.carsFiltersService;
            Object.entries(documentsPreview.data).forEach(([country, dataSources]) => {
                Object.entries(dataSources).forEach(([dataSource, competitors]) => {
                    const allowedCompetitors = allowedVendorsPerCountry?.[country]?.[dataSource] || [];
                    data.push({
                        country,
                        dataSource,
                        competitors: _.union(competitors, allowedCompetitors).map(value => ({
                            name: String(value),
                            value: String(value),
                        })),
                    });
                });
            });
            return data;
        }
        return this.data;
    }

    public get loadChangesOnly() {
        const data: ICompsetTableData[] = [];
        const { logsChanges, deletedItems } = this.carCompsetsService;
        logsChanges?.forEach(change => {
            data.push({
                country: change.country,
                dataSource: change.dataSource,
                competitors: _.union(change.newVendors, change.oldVendors).map(value => ({
                    name: String(value),
                    value: String(value),
                })),
            });
        });

        deletedItems?.forEach(item => {
            data.push({
                country: item.country,
                dataSource: item.dataSource,
                competitors: item.vendors.map(value => ({
                    name: String(value),
                    value: String(value),
                })),
            });
        });
        return data;
    }

    public close() {
        this.restoreCurrentVersion();
        this.$emit('close');
    }

    public isCompetitorRemoved(country: string, dataSource: string, competitor: string) {
        if (this.carCompsetsService.isHistoryLogsLoading || !this.carCompsetsService.logVersion) {
            return false;
        }
        const { allowedVendorsPerCountry } = this.carsFiltersService;
        return !allowedVendorsPerCountry?.[country]?.[dataSource]?.includes(competitor);
    }

    public isCompetitorNew(country: string, dataSource: string, competitor: string): boolean {
        const { isHistoryLogsLoading, logVersion, documentsPreview } = this.carCompsetsService;
        if (isHistoryLogsLoading || !logVersion || !documentsPreview?.data?.[country]?.[dataSource]) {
            return false;
        }
        return !documentsPreview.data[country][dataSource].includes(competitor);
    }

    public isDataSourceRemoved(country: string, dataSource: string) {
        if (this.carCompsetsService.isHistoryLogsLoading || !this.carCompsetsService.logVersion) {
            return false;
        }
        const { allowedVendorsPerCountry } = this.carsFiltersService;
        return !allowedVendorsPerCountry?.[country]?.[dataSource];
    }

    public isDataSourceNew(country: string, dataSource: string): boolean {
        const { isHistoryLogsLoading, logVersion, documentsPreview } = this.carCompsetsService;
        if (isHistoryLogsLoading || !logVersion || !documentsPreview?.data?.[country]) {
            return false;
        }
        return !documentsPreview.data[country]?.[dataSource]?.length;
    }

    public isCountryRemoved(country: string) {
        if (this.carCompsetsService.isHistoryLogsLoading || !this.carCompsetsService.logVersion) {
            return false;
        }
        const { allowedVendorsPerCountry } = this.carsFiltersService;
        return !allowedVendorsPerCountry?.[country] || !Object.keys(allowedVendorsPerCountry?.[country] || {}).length;
    }

    public isCountryNew(country: string): boolean {
        const { isHistoryLogsLoading, logVersion, documentsPreview } = this.carCompsetsService;
        if (isHistoryLogsLoading || !logVersion || !documentsPreview?.data) {
            return false;
        }
        return !Object.entries(documentsPreview?.data?.[country] || {}).filter(([_, vendors]) => vendors.length).length;
    }

    public restoreCurrentVersion() {
        this.carCompsetsService.logsChanges = null;
        this.carCompsetsService.logVersion = null;
        this.carCompsetsService.showChangesOnly = false;
        this.carCompsetsService.documentsPreview = null;
    }
}

