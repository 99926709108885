var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalWrapper',{attrs:{"options":{ width: '70%' },"onClose":_vm.close},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"compsets-history"},[_c('div',{staticClass:"compsets-history__title"},[_vm._v("Comp. Set")])])]},proxy:true},{key:"main",fn:function(){return [_c('div',{staticClass:"compsets-history__container"},[_c('div',{staticClass:"table-container"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData}},[_c('el-table-column',{attrs:{"prop":"country","label":"Country Name","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{class:{
                                'highlight': _vm.isCountryNew(scope.row.country),
                                'strike': _vm.isCountryRemoved(scope.row.country)
                            }},[_vm._v(_vm._s(scope.row.country))])]}}])}),_c('el-table-column',{attrs:{"prop":"dataSource","label":"Data Source","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{class:{
                                'highlight': _vm.isDataSourceNew(scope.row.country, scope.row.dataSource),
                                'strike': _vm.isDataSourceRemoved(scope.row.country, scope.row.dataSource)
                            }},[_vm._v(_vm._s(scope.row.dataSource))])]}}])}),_c('el-table-column',{attrs:{"prop":"competitors","label":"Competitors"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._l((scope.row.competitors),function(competitor,index){return [_c('span',{key:index,class:{
                                        'highlight': _vm.isCompetitorNew(scope.row.country, scope.row.dataSource, competitor.value),
                                        'strike': _vm.isCompetitorRemoved(scope.row.country, scope.row.dataSource, competitor.value)
                                    }},[_vm._v(" "+_vm._s(competitor.value)),(index < scope.row.competitors.length - 1)?_c('span',[_vm._v(", ")]):_vm._e()])]})],2)]}}])})],1)],1),_c('div',{staticClass:"compsets-history__bar"},[_c('CompsetsHistoryBar',{on:{"restoreCurrentVersion":_vm.restoreCurrentVersion}})],1)])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }